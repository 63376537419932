import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { UpdatePasswordComponent, UpdatePasswordComponentService } from '@spartacus/user/profile/components';

@Component({
  selector: 'app-custom-update-password',
  templateUrl: './custom-update-password.component.html',
  styleUrls: ['./custom-update-password.component.scss']
})
export class CustomUpdatePasswordComponent extends UpdatePasswordComponent implements OnInit {

  constructor(service: UpdatePasswordComponentService) {
    super(service);
  }


  ngOnInit(): void {
    /*
     * Removes original Spartacus password regex validation
     * in order to implement the custom password strength
     * validation.
     */
    this.form.controls['oldPassword'].removeValidators(CustomFormValidators.passwordValidator);
    this.form.controls['oldPassword'].addValidators(Validators.minLength(8));
    this.form.controls['newPassword'].removeValidators(CustomFormValidators.passwordValidator);
    this.form.controls['newPassword'].addValidators(Validators.minLength(8));
  }

}
