<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">

  <label>
    <span class="label-content">{{
      'customUpdatePassword.formular.oldPasswordLabel' | cxTranslate
    }}</span>
    <input
      aria-required="true"
      class="form-control"
      type="password"
      name="oldPassword"
      placeholder="{{ 'customUpdatePassword.formular.oldPasswordPlaceholder' | cxTranslate }}"
      formControlName="oldPassword"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [prefix]="'customUpdatePassword.formular.errors'"
      [control]="form.get('oldPassword')"
    ></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'customUpdatePassword.formular.newPasswordLabel' | cxTranslate
    }}</span>
    <input
      aria-required="true"
      class="form-control"
      type="password"
      name="newPassword"
      placeholder="{{
        'customUpdatePassword.formular.newPasswordPlaceholder' | cxTranslate
      }}"
      formControlName="newPassword"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [prefix]="'customUpdatePassword.formular.errors'"
      [control]="form.get('newPassword')"
    ></cx-form-errors>
    <app-kurz-password-checker [control]="form.get('newPassword')"></app-kurz-password-checker>
  </label>

  <label>
    <span class="label-content">{{
      'customUpdatePassword.formular.confirmPasswordLabel' | cxTranslate
    }}</span>
    <input
      aria-required="true"
      class="form-control"
      type="password"
      name="newPasswordConfirm"
      placeholder="{{
        'customUpdatePassword.formular.confirmPasswordPlaceholder' | cxTranslate
      }}"
      formControlName="newPasswordConfirm"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [prefix]="'customUpdatePassword.formular.errors'"
      [control]="form.get('newPasswordConfirm')"
    ></cx-form-errors>
  </label>

  <button class="btn btn-block btn-primary" [disabled]="form.disabled">
    {{ 'common.save' | cxTranslate }}
  </button>
</form>

